.app {
  padding: 0;
  width: 320px;
  margin: 0 auto; }

.main, .footer {
  padding: 20px; }

.container {
  width: 320px; }

.main ul {
  list-style: square;
  margin: 0;
  padding: 0 0 0 20px; }

.content-footer {
  padding-top: 21px;
  text-align: center;
  position: fixed;
  width: 320px;
  margin: 0 auto;
  bottom: 10px; }

.mainheader-title {
  font-size: 1.4em;
  text-align: center;
  background-color: #efefef;
  margin: 0;
  padding: 20px; }

.page-title {
  font-size: 1.2em;
  border-bottom: 1px dotted #ddd;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  margin-bottom: 10px; }

.list-outside {
  list-style-position: outside;
  padding-left: 20px; }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  left: -10000px; }
